import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Icon } from "react-icons-kit"
import { checkCircle } from "react-icons-kit/fa/checkCircle"

import ServiceMainSection from "../../components/MainSection/ServiceMainSection"
import PortfolioSection from "../../components/PortfolioSection"
import ContactSection from "../../components/ContactSection"
import CtaSection from "../../components/LayoutComp/CtaSection"
import SectionTitle from "../../components/LayoutComp/SectionTitle"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "home-remodeling" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery {
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const HomeRemodeling = ({ data }) => {
  const images = data.imagesJson

  let gallery = []

  images.gallery.forEach(result => {
    gallery = [...gallery, result.image.childImageSharp.fluid]
  })

  return (
    <Layout>
      <SEO title="Home Remodeling" />
      <ServiceMainSection image={images.background.childImageSharp.fluid}>
        <h1>Home remodeling</h1>
        <h2>Offering high-quality remodeling service since 1984.</h2>
      </ServiceMainSection>

      <section>
        <Container>
          <Row>
            <SectionTitle>
              We’re dedicated to making the renovation process{" "}
              <br className="d-none d-lg-inline" />
              as efficient and transparent as possible
            </SectionTitle>
          </Row>
          <Row className="justify-content-center">
            <Col sm={5}>
              <p>
                We are a family-owned and operated company, specializing in
                custom carpentry, full-home remodeling, home additions, and
                other general contracting tasks a homeowner would need to have
                the job done on their house.
              </p>
            </Col>
            <Col sm={5}>
              <p>
                As a full-service contractor and carpentry company, we have the
                ability to create any custom element and finish, making your
                home truly unique and standing out. With decades of experience,
                we can turn any of your visions into reality.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="service-description">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <Img
                className="card-img-top"
                fluid={images.firstSection.childImageSharp.fluid}
                alt="home remodeling - living room, custom stairs, and furniture"
              />
            </Col>
            <Col sm={11} lg={6} className="col-mod-pl align-self-center">
              <h3>
                We are equipped to handle <br className="d-none d-lg-inline" />{" "}
                every aspect of your project
              </h3>
              <div>
                <p>
                  Our expertise and services cover the full spectrum of
                  renovation, from a complete remodeling, adding a room, or
                  second story, to changing the exteriors. We always listen to
                  your needs and ideas and work hand and hand with you to turn
                  them into reality.
                </p>
                <p>
                  Our communication and attention to detail ensure a positive
                  experience and results that surpass all expectations.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="w-100 py-4 my-2 d-sm-block d-none"></div>
        <Container>
          <Row className="justify-content-center">
            <Col xs={11} lg={6} className="col-mod-pl align-self-center">
              <div>
                <Row>
                  <Col xs={2} sm={"auto"} className="icon">
                    <Icon size={32} icon={checkCircle} />
                  </Col>
                  <Col xs={10}>
                    <h4>35 years of experience</h4>
                    <p>
                      With over 35 years of experience, we offer the best course
                      of action for any project.
                    </p>
                  </Col>
                </Row>
                <div className="w-100 py-2"></div>
                <Row>
                  <Col xs={2} sm={"auto"} className="icon">
                    <Icon size={32} icon={checkCircle} />
                  </Col>
                  <Col xs={10}>
                    <h4>License and insurance</h4>
                    <p>
                      We are a hands-on team of fully licensed and insured
                      professionals.
                    </p>
                  </Col>
                </Row>
                <div className="w-100 py-2"></div>
                <Row>
                  <Col xs={2} sm={"auto"} className="icon">
                    <Icon size={32} icon={checkCircle} />
                  </Col>
                  <Col xs={10}>
                    <h4>Easy and stress-free</h4>
                    <p>
                      With years of experience, we offer you an easy and
                      stress-free remodeling experience.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              xs={{ order: "first", span: 12 }}
              lg={{ order: "last", span: 6 }}
            >
              <Img
                className="card-img-top"
                fluid={images.secondSection.childImageSharp.fluid}
                alt="home remodeling - bedroom"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <CtaSection>
        We listen to your needs and ideas <br className="d-none d-md-inline" />
        and turn them into reality
      </CtaSection>

      <PortfolioSection gallery={gallery}>
        <Row className="justify-content-center">
          <Col xs={12} md={11} lg={4}>
            <h3 className="h2">Efficient and dependable</h3>
          </Col>
          <Col xs={12} md={10} lg={8}>
            <p>
              Over 35 years of completing projects on time and within budget
              earned us a strong reputation. <br />A large portion of our
              business from both repeat and referral customers.
            </p>
            <p>
              No matter how big or small, we approach every job with the same
              attention to detail and quality customer service. We always put in
              the extra effort to get the job done right.
            </p>
          </Col>
        </Row>
      </PortfolioSection>

      <ContactSection />
    </Layout>
  )
}

export default HomeRemodeling
